<template>
  <div class="ReceiptDetails">
    <div class="ReceiptDetails_title">订单进度</div>
    <div class="ReceiptDetails_steps">
      <a-steps
        :current="queryData.logisticsProgress * 1"
        :initial="1"
        progressDot
      >
        <!-- <a-popover slot="progressDot" slot-scope="{ index, status, prefixCls }">
          <template slot="content">
            <span>step {{ index }} status: {{ status }}</span>
          </template>
          <span :class="`${prefixCls}-icon-dot`" />
        </a-popover> -->
        <a-step
          title="发货中"
          :sub-title="queryData.senderName"
          :description="queryData.senderTime"
        />
        <a-step
          title="已送达"
          :sub-title="queryData.arrivalName"
          :description="queryData.arrivalTime"
        />
        <a-step
          title="已验收"
          :sub-title="queryData.checkName"
          :description="queryData.checkTime"
        />
      </a-steps>
    </div>
    <div class="ReceiptDetails_title">订单信息</div>
    <div class="order_info">
      <div>
        订单单号: <span>{{ queryData.orderNo }} </span>
      </div>
      <div>
        配送方式: <span>{{ queryData.deliveryMethod }} </span>
      </div>
      <div>
        发货批次号:<span> {{ queryData.logisticsNo }}</span>
      </div>
      <div>
        发货时间: <span> {{ queryData.senderTime }} </span>
      </div>
    </div>
    <a-divider />
    <div class="ReceiptDetails_title">工地信息</div>
    <div class="order_info">
      <div>
        收货人: <span>{{ queryData.receivingName }} </span>
      </div>
      <div>
        联系电话: <span>{{ queryData.phone }} </span>
      </div>
      <div>
        供应商:<span> {{ queryData.supplierName }}</span>
      </div>
      <div>
        收货地址: <span> {{ queryData.receivingAddress }} </span>
      </div>
    </div>
    <a-divider />
    <div class="ReceiptDetails_title">材料信息</div>
    <div class="returnBtn">
      <a-button type="primary" @click="OrderReturn()"> 退货 </a-button>
    </div>
    <div>
      <a-table
        ref="table"
        size="small"
        rowKey="id"
        :columns="tableColumns"
        :data-source="dataSource"
        :pagination="false"
        :scroll="{ x: '1300px', y: 'calc(82vh - 150px )' }"
        tableLayout="fixed"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <!-- 材料类别 -->
        <span slot="type" slot-scope="text, record" class="action">
          {{ record.material.typeName }}
        </span>
        <!-- 材料名称 -->
        <span slot="logisticsNo" slot-scope="text, record" class="action">
          {{ record.material.name }}
        </span>
        <!-- 型号 -->
        <span slot="orderDetailsId" slot-scope="text, record" class="action">
          {{ record.material.modelCode }}
        </span>
        <!-- 单位 -->
        <span slot="supplierId" slot-scope="text, record" class="action">
          {{ record.material.unit }}
        </span>
        <!-- 小计 -->

        <span slot="createBy" slot-scope="text, record" class="action">
          {{ record.supplierPrice * record.sentNumber }}
        </span>
        <!-- 验收结果 -->
        <span slot="checkState" slot-scope="text" class="action">
          {{ text | AcceptanceResults }}
        </span>
        <!-- 操作 -->
        <!-- <span slot="action" slot-scope="text, record" class="action">
        <a @click="GoToOrderDetails(record, '订单详情')">详情</a>
         </span> -->
      </a-table>
    </div>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="true"
      :visible="visibleDrawer"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <ReturnInformation :record="record" @onClose="onClose" />
    </a-drawer>
  </div>
</template>

<script>
import { OrderReturn, LogisticsReturn } from "@/api/apiJF/material";
import ReturnInformation from "./ReturnInformation.vue";
const columns = [
  {
    title: "材料编号",
    width: "100px",
    dataIndex: "materialCode",
    ellipsis: true,
    align: "center",
  },
  {
    title: "材料类别",
    width: "100px",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "材料名称",
    // width: "90px",
    dataIndex: "logisticsNo",
    scopedSlots: { customRender: "logisticsNo" },
    align: "center",
    ellipsis: true,
  },
  {
    title: "型号",
    width: "100px",
    dataIndex: "orderDetailsId",
    scopedSlots: { customRender: "orderDetailsId" },
    align: "center",
  },

  {
    title: "单位",
    width: "90px",
    dataIndex: "supplierId",
    scopedSlots: { customRender: "supplierId" },
    align: "center",
  },
  {
    title: "单价(元)",
    width: "90px",
    dataIndex: "supplierPrice",
    align: "center",
  },
  {
    title: "数量",
    width: "90px",
    dataIndex: "sentNumber",
    align: "center",
  },
  {
    title: "小计(元)",
    width: "90px",
    dataIndex: "createBy",
    scopedSlots: { customRender: "createBy" },
    align: "center",
  },
  {
    title: "验收日期",
    width: "90px",
    dataIndex: "checkTime",
    align: "center",
  },
  {
    title: "验收结果",
    width: "100px",
    dataIndex: "checkState",
    scopedSlots: { customRender: "checkState" },
    align: "center",
  },
  {
    title: "退换货数量",
    width: "100px",
    dataIndex: "returnNumber",
    align: "center",
  },
];
export default {
  name: "ReceiptDetails",
  components: { ReturnInformation },
  data() {
    // this.columns = columns;
    return {
      queryData: {},
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      record: "",
      visibleDrawer: false,
      DrawerTitle: "请填写退换货数量",
    };
  },
  created() {
    this.tableColumns = columns;
    // console.log("this.", this.$route.query.query);
    this.queryData = JSON.parse(this.$route.query.query);
    console.log("this.queryData", this.queryData);
    this.dataSource = this.queryData.logisticsDetailsList;
  },
  mounted() {},
  methods: {
    onClose() {
      this.visibleDrawer = false;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      //   console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 退货
    OrderReturn() {
      // console.log("this.selectedRows", this.selectedRows);
      this.record = JSON.stringify(this.selectedRows);
      this.visibleDrawer = true;
    },
  },
};
</script>
<style lang="less" scoped>
.ReceiptDetails_title {
  text-align: left;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 800;
}
.ReceiptDetails {
  margin-top: 50px;
  .ReceiptDetails_steps {
    width: 80%;
    text-align: left;
  }
}
.order_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    margin: 10px 0;
    font-weight: 600;
    width: 33%;
    text-align: left;
    span {
      color: #999999;
      padding-left: 10px;
      font-weight: 400;
    }
  }
}
.returnBtn {
  text-align: left;
  margin: 10px 0;
}
</style>